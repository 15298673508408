import styled from "styled-components";

export const Text = styled.div`
  font-family: "Open sans", serif;
  font-size: 22px;
  font-weight: 300;
  margin: 40px 0;
`;

export const TextBlock = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 30px;
  & > * {
    flex: 1 1 0px;
  }
  & img {
    width: 100%;
  }
  ${(props) =>
    props.reverse === "reverse" ? "flex-direction: row-reverse;" : ""}
  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`;
