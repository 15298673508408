import React from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import Home from "./pages";
import HomeEng from "./pages/index_eng";
import Festmeny from "./pages/festmeny";
import FestmenyEng from "./pages/festmeny_eng";
import Szolj from "./pages/szolj";
import SzoljEng from "./pages/szolj_eng";
import Kiallitas from "./pages/kiallitas";
import KiallitasEng from "./pages/kiallitas_eng";
import Anton from "./pages/anton";
import AntonEng from "./pages/anton_eng";
import Press from "./pages/press";
import PressEng from "./pages/press_eng";
// import NFT from "./pages/nft";
import NFTEng from "./pages/nft_eng";
import styled from "styled-components";

function getLibrary(provider) {
  return new Web3(provider);
}

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Container>
        <BrowserRouter>
          <Header>
            <Navbar />
          </Header>
          <Routes>
            <Route path="/hu" exact element={<Home />} />
            <Route path="/" exact element={<HomeEng />} />
            <Route path="/hu/festmeny" element={<Festmeny />} />
            <Route path="/hu/szolj" element={<Szolj />} />
            <Route path="/hu/kiallitas" element={<Kiallitas />} />
            <Route path="/hu/anton" element={<Anton />} />
            <Route path="/hu/press" element={<Press />} />
            <Route path="/painting" element={<FestmenyEng />} />
            <Route path="/speak" element={<SzoljEng />} />
            <Route path="/exhibition" element={<KiallitasEng />} />
            <Route path="/anton" element={<AntonEng />} />
            <Route path="/press" element={<PressEng />} />
            <Route path="/nft" element={<NFTEng />} />
            {/* <Route path="/hu/nft" element={<NFT />} /> */}
          </Routes>
        </BrowserRouter>
      </Container>
    </Web3ReactProvider>
  );
}

export default App;

const Container = styled.div`
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
`;
const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
