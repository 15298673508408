import React from "react";
import styled from "styled-components";
import DecoHeading from "../components/common/DecoHeading";
import { Text, TextBlock } from "../components/common/Text";

const Pixel = () => {
  return (
    <>
      <MenuPlaceholder></MenuPlaceholder>
      <ContentContainer bgColor="tortfeher">
        <DecoHeading heading="Have a say" color="lila"></DecoHeading>
        <Text>
          <TextBlock>
            <LongText>
              <p>
                {" "}
                The Whoever pixel is a game created on the ILGON blockchain,
                giving anyone who wants to have a say in the life of a physical
                work of art a chance to do so.
              </p>

              <p>
                Next to NFT, there is the Netuddki painting, in which the artist
                depicts Satoshi Nakamoto in a stunning way. No one can own this
                painting, it has no owner, it has a custodian whose mission is
                to preserve the work for the future and to appear in as many
                places as possible, so that more people can learn about the
                mystery of Satoshi Nakamoto and this whole mysterious world
                today.
              </p>

              <p>
                We would like to leave a trace in the form of NFT commemorative
                plaques that symbolize and celebrate the original work. The
                collection will appear on the Ilgon blockchain on December 15,
                2021 and includes 21,000 commemorative plaques, any of which can
                be one or more pieces, creating the opportunity for a long
                journey we can experience together.
              </p>

              <p>
                In the possession of the NFT commemorative plaque, you have the
                opportunity to have a say in the further fate of the work, where
                for how long and in what form the physical work should appear.
                Be part of this adventure.
              </p>

              <p>
                Description of the game: the game will start on December 15,
                2021. If you would like to be informed about the game and know
                more details, please enter your email address or visit us in two
                weeks, when we will release the details.
              </p>
            </LongText>
          </TextBlock>
        </Text>
      </ContentContainer>
    </>
  );
};

export default Pixel;

const MenuPlaceholder = styled.div`
  height: 80px;
  width: 100%;
  background: url("/hatterek/szurke_minta.jpg");
`;

const ContentContainer = styled.div`
  background-image: url("/hatterek/${(props) => props.bgColor}_hatter_1.jpg");
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center top;
  padding: 5vw 8vw;
  color: ${(props) => (props.bgColor === "szurke" ? "white" : "#808080")};
`;
const LongText = styled.div`
  & p {
    margin-block-end: 25px;
  }
`;
