import Separator from "../components/common/Separator";
import React from "react";
import styled from "styled-components";
import { InnerHeading } from "../components/common/InnerHeading";
import { InnerLink } from "../components/common/InnerLink";
import { ContentContainer } from "../components/common/ContentContainer";
import { Text, TextBlock } from "../components/common/Text";
import DecoHeading from "../components/common/DecoHeading";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const Home = () => {
  const [pic1Front, setPic1Front] = useState(false);
  useEffect(() => {
    document.title = "WhoEver - Anton Molnar";
    const interval = setInterval(() => {
      setPic1Front(!pic1Front);
    }, 5000);
    return () => clearInterval(interval);
  }, [pic1Front]);

  return (
    <>
      <VideoContainer>
        <VideoBG>
          <VideoPlaceholder src="/hatterek/nyito_placeholder_allo.png" />
          <Video autoPlay muted loop playsInline id="myVideo">
            <source
              src="/mozgo_hatterek/NeTuddKi_12_sec_medium.mp4"
              type="video/mp4"
            />
          </Video>

          <MainHeading>
            <div>WHO</div>
            <div>EVER</div>
          </MainHeading>
        </VideoBG>
      </VideoContainer>
      <ContentContainer bgColor="szurke">
        <DecoHeading heading="Painting" color="lila"></DecoHeading>
        <Text>
          <TextBlock>
            <LongText>
              <p>
                The special feature of Anton Molnár's paintings is that he
                depicts the modern world with a classical technique. He loves
                the man of the age and he loves portraying it, which is why the
                Netuddki painting was born, from which the artist created
                Netuddki, which is the first, only and also the last NFT.{" "}
              </p>

              <p>
                The way these works are depicted is special because the starter
                of the cryptographic revolution is portrayed from behind,
                without a face, simply as a hooded figure, completely deprived
                of his personal characteristics, so that only the outlines of
                the figure reveal his human nature.{" "}
              </p>

              <p>
                Although Anton Molnar's work is a depiction of Satoshi by the
                first world-famous classical painter, his real value lies in his
                ability to interpret many of the messages of Satoshi's identity
                and work - and the social and technological revolution he
                launched - while preserving its depth and diversity. The
                realistic figure in front of the abstract background is
                amazingly depicted as we arrive in an unknown new world.
              </p>
            </LongText>
            <div>
              <img
                src={
                  pic1Front
                    ? "/NeTuddKi_kepek/NeTuddKi_1-2_transparent.png"
                    : "/NeTuddKi_kepek/NeTuddKi_2-1_transparent.png"
                }
                alt="NeTuddKi"
              />
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
      <OpenSeaContainer>
        <br />
        <br />
        <br />
        <nft-card
          contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
          tokenId="98196178464466689993116238850081612969887625248381346180371175427539766083585"
        ></nft-card>
        <br />
        <br />
        <br />
      </OpenSeaContainer>
      <Separator>
        <div>
          The official AM address
          <br />
          <br />
        </div>
        <div>0xd9191143581017d3035AD4AA3D5C2452278Ea0a2</div>
      </Separator>
      <ContentContainer bgColor="tortfeher">
        <DecoHeading heading="Anton Molnar" color="lila"></DecoHeading>
        <Text>
          <TextBlock reverse="reverse">
            <LongText>
              <p>
                {" "}
                Anton Molnar was born in Budapest in 1957 and moved to Paris in
                1988 with his family, where he has lived and worked ever since.
                From the very beginning, he had such famous collaborators as
                Pierre Cardin and the Opera Gallery. His paintings can also be
                found in the private collections of art foundations,
                world-famous companies (Louis Vuitton, Moët & Henessy) and
                celebrities (Cindy Crawford, Jacques Chirac, Takeshi Sekiguchi).{" "}
              </p>

              <p>
                His paintings were among Picasso, Andy Warhol, Marc Chagall and
                Jasper Johns at major auctions in Tokyo. His life, work and
                attitude are excellently characterized by his exhibition
                entitled “Everything at Once” held in Hungary in 2020. He sees
                the subject in everything and we see him in everything. In his
                works, there is always the NOW, his personal creative life,
                interest and his own style.{" "}
              </p>

              <p>
                He shares his views of the world through his pictures. In his
                paintings, the content is often sought indirectly or covertly.
                He has several exhibitions around the world each year, from
                Paris to Tokyo, from Singapore to Los Angeles, where his
                collectors always follow him. Anton Molnár's works radiate a
                love of life and a real cosmopolitan approach. A world traveler
                who translates momentary impressions into his creations.
              </p>
              <p>
                <NavLink to="/anton" style={{ color: "#808080" }}>
                  Read more &gt;
                </NavLink>
              </p>
            </LongText>
            <div>
              <img src="/tartalom_oldalak/anton_molnar.jpg" alt="Anton" />
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
      <ContentContainer bgColor="szurke">
        <DecoHeading heading="Satoshi Nakamoto" color="lila"></DecoHeading>
        <Text>
          <TextBlock>
            <LongText>
              <p>
                {" "}
                Perhaps the greatest mystery of our time is Satoshi Nakamoto,
                who created the world’s first working blockchain system. He
                published the Bitcoin White Paper in 2008 and released Bitcoin
                on January 3, 2009.{" "}
              </p>

              <p>
                Creating this required industry-wide knowledge, economic, legal,
                financial, cryptographic, and programming knowledge. His message
                is clear: the times when the systems that operate the world are
                ruled by central powers are over.{" "}
              </p>

              <p>
                Free Internet, freedom of information, and open source IT
                systems offer unprecedented opportunities for everyone to
                participate in the next breakthrough, and to build a common
                future based on non-power, technologically more advanced, better
                and brighter future for us all.
              </p>
            </LongText>
            <div>
              <img src="/tartalom_oldalak/Satoshi_kulon.png" alt="Satoshi" />
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
      <OpenSeaContainer>
        <br />
        <br />
        <br />
        <nft-card
          contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
          tokenId="98196178464466689993116238850081612969887625248381346180371175427539766083585"
        ></nft-card>
        <br />
        <br />
        <br />
      </OpenSeaContainer>
      <Separator>
        <InnerHeading>CONTACT</InnerHeading>
        <InnerLink
          href="mailto:whoever@whoever.wiki"
          rel="noreferrer nooponer"
          targt="_blank"
        >
          whoever@whoever.wiki
        </InnerLink>
      </Separator>
      <ContentContainer bgColor="szurke">
        <DecoHeading heading="Roadmap" color="lila"></DecoHeading>
        <Text>
          <TextBlock>
            <div className="desktop">
              <img src="/roadmap/roadmap_fekvo_ENG.png" alt="roadmap" />
            </div>
            <div className="mobile">
              <img src="/roadmap/roadmap_allo_ENG.png" alt="roadmap" />
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
    </>
  );
};

export default Home;

const VideoContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const MainHeading = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%); /* % of current element */
  font-size: 10vw;
  font-family: "Cinzel", serif;
  color: white;
  text-shadow: -2px 3px #000000;
  & div {
    font-family: "Cinzel", serif;
    display: inline-block;
    line-height: 10vw;
    margin-bottom: -40px;
  }
  @media screen and (max-width: 520px) {
    font-size: 20vw;
    width: 70vw;
  }
`;

const VideoBG = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`;

const Video = styled.video`
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, calc(-1px - 50%)); /* % of current element */
`;
const VideoPlaceholder = styled.img`
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%); /* % of current element */
`;

const OpenSeaContainer = styled.div`
  width: 100%;
  padding: 0 5vw;
  background: #808080;
`;

const LongText = styled.div`
  & p {
    margin-block-end: 25px;
  }
`;
