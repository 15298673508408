import React from "react";
import styled from "styled-components";
import DecoHeading from "../components/common/DecoHeading";
import { Text, TextBlock } from "../components/common/Text";
import { useEffect, useState } from "react";

const Pixel = () => {
  const [pic1Front, setPic1Front] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setPic1Front(!pic1Front);
    }, 5000);
    return () => clearInterval(interval);
  }, [pic1Front]);

  return (
    <>
      <MenuPlaceholder></MenuPlaceholder>
      <ContentContainer bgColor="tortfeher">
        <DecoHeading heading="Exhibition" color="lila"></DecoHeading>

        <Text>
          <TextBlock>
            <LongText>
              <p>
                With a series of free exhibitions organized by Dlabs Kft.,
                everyone has the opportunity to get to know the work of the
                world-famous contemporary painter Anton Molnar, of Satoshi
                Nakamoto, the mysterious founder of Bitcoin.
              </p>

              <p>
                They can take a closer look at the fantastic experience that the
                hyperrealism of the painting provides. In the modern painting
                painted with classical technique, the realistic figure in front
                of the abstract background amazingly depicts as we arrive in an
                unknown new world.
              </p>

              <p>
                During the series of the exhibition, the work will be exhibited
                in several places, in different spaces, thus highlighting that
                the attraction of a work is not where it is located, but what
                its content is.
              </p>

              <p>
                We look forward to seeing you at the following locations and
                times:
              </p>

              <p>
                Milton Friedman University - 23.11.2021.
                <br />
                Lumas Budapest - 25.11.2021.
                <br />
                Bank Centre Budapest - 03.12.2021.
              </p>

              <p>More locations coming soon.</p>

              <p>
                The special feature of Anton Molnár's paintings is that he
                depicts the modern world with a classical technique. He loves
                the man of the age and he loves portraying it, which is why the
                Netuddki painting was born, from which the artist created
                Netuddki, which is the first, only and also the last NFT.{" "}
              </p>

              <p>
                The way these works are depicted is special because the starter
                of the cryptographic revolution is portrayed from behind,
                without a face, simply as a hooded figure, completely deprived
                of his personal characteristics, so that only the outlines of
                the figure reveal his human nature.{" "}
              </p>

              <p>
                Although Anton Molnar's work is a depiction of Satoshi by the
                first world-famous classical painter, his real value lies in his
                ability to interpret many of the messages of Satoshi's identity
                and work - and the social and technological revolution he
                launched - while preserving its depth and diversity. The
                realistic figure in front of the abstract background is
                amazingly depicted as we arrive in an unknown new world.
              </p>
            </LongText>
            <div>
              <img
                src={
                  pic1Front
                    ? "/NeTuddKi_kepek/NeTuddKi_1-2_transparent.png"
                    : "/NeTuddKi_kepek/NeTuddKi_2-1_transparent.png"
                }
                alt="WhoEver"
              />
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
    </>
  );
};

export default Pixel;

const MenuPlaceholder = styled.div`
  height: 80px;
  width: 100%;
  background: url("/hatterek/szurke_minta.jpg");
`;

const ContentContainer = styled.div`
  background-image: url("/hatterek/${(props) => props.bgColor}_hatter_1.jpg");
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center top;
  padding: 5vw 8vw;
  color: ${(props) => (props.bgColor === "szurke" ? "white" : "#808080")};
`;
const LongText = styled.div`
  & p {
    margin-block-end: 25px;
  }
`;
