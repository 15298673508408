import React from "react";
import styled from "styled-components";
import DecoHeading from "../components/common/DecoHeading";
import { Text, TextBlock } from "../components/common/Text";
import { ContentContainer } from "../components/common/ContentContainer";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
const Pixel = () => {
  return (
    <>
      <ScrollToTopOnMount />
      <MenuPlaceholder></MenuPlaceholder>
      <ContentContainer bgColor="tortfeher">
        <DecoHeading heading="Anton Molnar" color="lila"></DecoHeading>
        <Text>
          <TextBlock>
            <LongText>
              <p>
                Anton Molnar (Antal Molnár) (Budapest, 1957–) A Hungarian artist
                living in Paris, one of the most successful and well-known
                Hungarian painters living abroad. He spent his childhood in
                Hungary and in Bamako, Africa, thanks to his mathematician
                father. His first work depicts Kálmán Latabár, which he painted
                at the age of three.
              </p>
              <p style={{ fontWeight: 500 }}>Biography</p>
              <p>
                The greatest impact came at the Secondary School of Fine and
                Applied Arts, where he graduated in painting in 1976 and then
                worked at the Museum of Fine Arts, until he was admitted to the
                College of Fine Arts, where he graduated as a painter in 1983.
                He designed record covers, made posters and graphics,
                photographed Tina Turner and the band Dire Straits at their
                concert in Budapest.
              </p>
              <p>
                At the time, it seemed utterly hopeless to thrive on his
                painting at home and support his family from his art. Until
                1986, he remained in college for a master’s degree. This year he
                was offered a graphic job by the UN in Geneva and then settled
                in Paris with his family two years later, but he never forgot
                his homeland. He always remained Hungarian in his heart and
                thoughts.
              </p>
              <p>
                In France he first exhibited his paintings in smaller galleries,
                later he started working with Pierre Cardin, with whom he met in
                Hungary. After three successful exhibitions, he came to the
                Opera Gallery, where he was present with his works for almost
                two decades.
              </p>
              <p>
                Thanks to a lucky coincidence, he meets Takeshi Sekiguchi in
                Japan, who only collects works by Picasso and Anton to this day.
                His paintings are from art foundations and famous private
                collections (Cindy Crawford, Jacques Chirac), and he also paints
                to order from world-famous companies (Louis Vuitton, Moët &
                Henessy). His works were among Picasso, Andy Warhol, Marc
                Chagall and Jasper Johns at major auctions in Tokyo.
              </p>
              <p>
                His studies and childhood drawings place the artist and his work
                in the age where he lives. His life, work and attitude are
                excellently characterized by his exhibition entitled “Everything
                at Once” held in Hungary in 2020. He sees the subject in
                everything and we see him in everything. His works are always
                there now, his personal creative life, interest and own style.
                He is constantly following his age, his vision is modern and his
                technique is classic.
              </p>
              <p>
                To this day, Hungarian roots can be found in his art. He shares
                his view of the world through his pictures. He documents his
                travels with his paintings, the content of which is often sought
                indirectly or covertly.
              </p>
              <p>
                He holds several exhibitions around the world each year, from
                Paris to Tokyo, Singapore to Los Angeles, where his collectors
                follow him. During his exhibitions, he stands almost naked in
                front of the audience and shows his soul, showing what he is
                capable of.
                <br />
                He often visits home, and during these visits more and more
                Hungarian-themed pictures appear on the screen. He has had
                several exhibitions in Hungary so far.
              </p>
              <p>
                He got his first job in Budapest from the director of the
                Stadium Hotel, who asked him to paint the history of the
                Olympics. Of course, in addition to the big world stars such as
                Mark Spitz, Sonja Henie, Bob Beamon, András Balczó, the Golden
                Team, Pap Laci and the Hungarian dagger team also played.
              </p>
              <p>
                In 2004, he made a 50-minute portrait film entitled Painter's
                Travel Diary - Portrait of Anton Molnar, which was also shown on
                Duna TV.
              </p>
              <p>
                In 2012, he won the 40th "Salon des artistes" in Dijon for
                approx. Ahead of 500 participating artists.
              </p>
              <p style={{ fontWeight: 500 }}>His art</p>
              <p>
                He works with a classic technique developed by the Flemish and
                Italian Renaissance masters. The technique of calligraphy, which
                is often mentioned in his paintings, is strongly reminiscent of
                15th and 17th century Dutch painters, but he is also strongly
                influenced by the American “pop art” of the 1980s. It’s like
                painting on wood, which gives your pictures a special curiosity.
              </p>
              <p>
                Anton Molnár sees perfection in simple objects. Pattern the
                subject with almost photo fidelity, be it your own shoes or hat.
                Hold the object at a special angle so that the light falls on it
                at a strange angle. This particular point of view lends mystery
                to everyday tools, and meticulous elaboration elevates them to
                hyperrealistic heights. A real cosmopolitan approach emerges
                from Anton Molnár's works. A world traveler who translates
                momentary impressions into his creations.
              </p>
              <p>
                Still lifes, portraits and street arts can also be found in his
                work, which provide an insight into the complex humanist world
                of Anton Molnár. His portraits, according to his own confession,
                also depict a journey, behind which stand souls, fate and
                cultures emerge from it. He is diverse, open-minded, inspired by
                the great achievements and masters before him. He loves the man
                of the age and loves to document the life of it.
              </p>
              <p>
                Anton Molnár is not only a painter, but also a photographer and
                graphic artist. The camera often appears in his paintings, with
                the painter reflected from an object with his camera.
                <br />
                His graphic artist being is also present in his paintings, in
                which, in a peculiar way, there is almost always a written text
                that conveys content in addition to the figure or object
                depicted.
              </p>
              <p style={{ fontWeight: 500 }}>Quote</p>
              <p>
                "Anton Molnár is a hedonistic artist. Not like Trimalchio, known
                from Satyricon, a Roman neo-rich who rests unbridledly in his
                wealth, but like Krúdy's hero, Sindbád, the traveler. Not
                avaricious, but a contemplative gourmet. Tastes and smells,
                admirer of the beauty of women, collector of impressions.
                Impressions of life, prints of memories on the canvas. Are they
                our own experiences or the desires of the spectators? Not
                important. We travel through the ages, from the Middle Ages to
                the world of the monarchy, from the 60’s kid toys to the
                ascension of metropolis today. We travel from landscape to
                landscape, from the Hungarian wilderness through New York to the
                Pacific archipelago.
                <br />
                We travel between styles, from the beauty of renaissance and
                baroque to the garden of Monet, through the advertising world of
                pop art, over the splashed, dripped technique of the tasism, to
                the folklore of the suburban streets. And in the end, we always
                get home.
              </p>
              <p>ROCKENBAUER Zoltán, PhD Curator, Art Gallery - Kunsthalle</p>
            </LongText>
          </TextBlock>
        </Text>
      </ContentContainer>
    </>
  );
};

export default Pixel;

const MenuPlaceholder = styled.div`
  height: 80px;
  width: 100%;
  background: url("/hatterek/szurke_minta.jpg");
`;

const LongText = styled.div`
  & p {
    margin-block-end: 25px;
  }
`;
