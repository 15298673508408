import React from "react";
import styled from "styled-components";
import DecoHeading from "../components/common/DecoHeading";
import { Text, TextBlock } from "../components/common/Text";
import { useState, useEffect } from "react";

const Pixel = () => {
  const [pic1Front, setPic1Front] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setPic1Front(!pic1Front);
    }, 5000);
    return () => clearInterval(interval);
  }, [pic1Front]);

  return (
    <>
      <MenuPlaceholder></MenuPlaceholder>
      <ContentContainer bgColor="tortfeher">
        <DecoHeading heading="Kiállítás" color="lila"></DecoHeading>

        <Text>
          <TextBlock>
            <LongText>
              <p>
                A Dlabs Kft. szervezésében egy ingyenes kiállítás sorozat
                keretében mindenkinek lehetősége nyílik megismerni a világhírű
                kortárs festő Anton Molnar alkotását Satoshi Nakamotoról, a
                Bitcoin titokzatos alapítójáról.{" "}
              </p>

              <p>
                Közelebbről is átélhetik azt a fantasztikus élményt, amit a
                festmény hiperrealisztikus ábrázolása nyújt. A klasszikus
                technikával megfestett modern festményen a festő meglátása
                szerint is bámulatosan ábrázolja az absztrakt háttér előtt álló
                realista alak, ahogy megérkezünk egy ismeretlen új világba.{" "}
              </p>

              <p>
                A kiállítás sorozat alkalmával többféle helyen, többféle térben
                lesz elhelyezve a mű, ezzel rávilágítva arra, hogy egy mű
                vonzereje nem abban van, hogy hol található, hanem, hogy mi a
                tartalma.{" "}
              </p>

              <p>
                Sok szeretettel várunk mindenkit az alábbi helyszíneken és
                időpontokban:{" "}
              </p>

              <p>
                Milton Friedman Egyetem - 2021.11.23. <br />
                Lumas Budapest - 2021.11.25.
                <br />
                Bank Center Budapest - 2021.12.03.
              </p>

              <p>További helyszínek hamarosan.</p>
              <p>
                Anton Molnár festményeinek különlegessége abban rejlik, hogy egy
                klasszikus technikával ábrázolja a modern világot. Szereti a kor
                emberét és szereti a kor emberének ábrázolását ezért is
                született meg a Netuddki festmény, melyből a művész megalkotta a
                Netuddki-t, ami az első, egyetlen és egyben az utolsó NFT
                alkotása.{" "}
              </p>
              <p>
                Ezen művek ábrázolás módja attól különleges, hogy a
                kriptoforradalom kirobbantóját hátulról, arc nélkül, egyszerűen
                csak kapucnis alakként jeleníti meg, teljesen megfosztva
                személyes karakterisztikáitól, így emberi mivoltáról csak az
                alak körvonalai árulkodnak.{" "}
              </p>
              <p>
                Bár Anton Molnár munkája az első világhírű klasszikus festő
                által készített Satoshi ábrázolás, valódi értéke abban rejlik,
                hogy képes egy alkotásban tolmácsolni Satoshi identitásának és
                munkásságának - illetve az általa indított társadalmi és
                technológiai forradalomnak - megannyi üzenetét, míg megőrzi
                annak mélységét és sokrétűségét. Bámulatosan ábrázolja az
                absztrakt háttér előtt álló realista alak, ahogy megérkezünk egy
                ismeretlen új világba.
              </p>
            </LongText>
            <div>
              <img
                src={
                  pic1Front
                    ? "/NeTuddKi_kepek/NeTuddKi_1-2_transparent.png"
                    : "/NeTuddKi_kepek/NeTuddKi_2-1_transparent.png"
                }
                alt="NeTuddKi"
              />
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
    </>
  );
};

export default Pixel;

const MenuPlaceholder = styled.div`
  height: 80px;
  width: 100%;
  background: url("/hatterek/szurke_minta.jpg");
`;

const ContentContainer = styled.div`
  background-image: url("/hatterek/${(props) => props.bgColor}_hatter_1.jpg");
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center top;
  padding: 5vw 8vw;
  color: ${(props) => (props.bgColor === "szurke" ? "white" : "#808080")};
`;

const LongText = styled.div`
  & p {
    margin-block-end: 25px;
  }
`;
