import React from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useState } from "react";

const Navbar = () => {
  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };
  const [isOpen, setIsOpen] = useState(false);
  return !usePathname().includes("/hu") ? (
    <Nav>
      <NavLink
        style={{ backgroundColor: "none" }}
        to="/"
        home="home"
        logo="logo"
      >
        <Logo
          src="/logo/NeTuddKi_logo.png"
          alt="logo"
          unselectable="on"
          onDragStart={() => false}
        />
      </NavLink>
      {isOpen ? (
        <Times onClick={() => setIsOpen(!isOpen)} />
      ) : (
        <Bars onClick={() => setIsOpen(!isOpen)} />
      )}
      <NavMenu active={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <NavLink to="/" activeStyle home="home">
          HOME
        </NavLink>
        <NavSeparator />
        <NavLink to="/exhibition" activeStyle>
          EXHIBITION
        </NavLink>
        <NavSeparator />
        <NavLink to="/speak" activeStyle>
          HAVE&nbsp;A&nbsp;SAY<Subscript>GAME</Subscript>
        </NavLink>
        <NavSeparator />
        <NavLink to="/anton" activeStyle>
          ANTON&nbsp;MOLNAR
        </NavLink>
        <NavSeparator />
        <NavLink to="/press" activeStyle>
          PRESS
        </NavLink>
        <NavSeparator />
        <ExternalLink
          href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/98196178464466689993116238850081612969887625248381346180371175427539766083585"
          target="_blank"
          rel="noopener noreferrer"
        >
          AUCTION
        </ExternalLink>
        <NavSeparator />
        <NavLink to="/hu" activeStyle>
          HUN
        </NavLink>
      </NavMenu>
    </Nav>
  ) : (
    <Nav>
      <NavLink
        style={{ backgroundColor: "none" }}
        to="/hu"
        home="home"
        logo="logo"
      >
        <Logo
          src="/logo/NeTuddKi_logo.png"
          alt="logo"
          unselectable="on"
          onDragStart={() => false}
        />
      </NavLink>
      {isOpen ? (
        <Times onClick={() => setIsOpen(!isOpen)} />
      ) : (
        <Bars onClick={() => setIsOpen(!isOpen)} />
      )}
      <NavMenu active={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <NavLink to="/hu" activeStyle home="home">
          HOME
        </NavLink>
        <NavSeparator />
        <NavLink to="/hu/kiallitas" activeStyle>
          KIÁLLÍTÁS
        </NavLink>
        <NavSeparator />
        <NavLink to="/hu/szolj" activeStyle>
          SZÓLJ&nbsp;BELE<Subscript>JÁTÉK</Subscript>
        </NavLink>
        <NavSeparator />
        <NavLink to="/hu/anton" activeStyle>
          ANTON MOLNAR
        </NavLink>
        <NavSeparator />
        <NavLink to="/hu/press" activeStyle>
          PRESS
        </NavLink>
        <NavSeparator />
        <ExternalLink
          href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/98196178464466689993116238850081612969887625248381346180371175427539766083585"
          target="_blank"
          rel="noopener noreferrer"
        >
          AUKCIÓ
        </ExternalLink>
        <NavSeparator />
        <NavLink to="/" activeStyle>
          ENG
        </NavLink>
      </NavMenu>
    </Nav>
  );
};

export default Navbar;

const Nav = styled.nav`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 50px 0.5rem 0;
  z-index: 10;
  position: relative;

  /* Third Nav */
  /* justify-content: flex-start; */
`;

const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  position: relative;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 50px;
  cursor: pointer;
  font-family: "Open sans", serif;
  font-size: 18px;
  font-weight: 300;
  &.active {
    background-color: ${(props) =>
      props.home === "home" ? "none" : "#ffd57f"};
    color: ${(props) => (props.home === "home" ? "white" : "#808080")};
    border-radius: 5px;
  }
  &:hover {
    background-color: ${(props) =>
      props.logo === "logo" ? "none" : "#ffd57f"};
    color: #808080;
    border-radius: 5px;
  }
  @media screen and (max-width: 920px) {
    &:first-child {
      margin-top: 10px;
    }
    min-height: 40px;
    max-height: 40px;
    border-radius: 0 !important;
    width: 100%;
  }
`;

const ExternalLink = styled.a`
  color: gold;
  display: flex;
  position: relative;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 50px;
  cursor: pointer;
  font-family: "Open sans", serif;
  font-size: 18px;
  font-weight: 300;
  &:hover {
    background-color: ${(props) =>
      props.logo === "logo" ? "none" : "#ffd57f"};
    color: #808080;
    border-radius: 5px;
  }
  @media screen and (max-width: 920px) {
    &:first-child {
      margin-top: 10px;
    }
    min-height: 40px;
    max-height: 40px;
    border-radius: 0 !important;
    width: 100%;
  }
`;

const Subscript = styled.div`
  position: absolute;
  bottom: -1px;
  right: 18px;
  font-size: 12px;
  color: gold;
  font-weight: 700;
  ${NavLink}:hover & {
    color: black;
  }
  ${NavLink}.active & {
    color: black;
  }
`;

const NavSeparator = styled.div`
  width: 50px;
  min-height: 1px;
  max-height: 1px;
  margin: 3px 0 3px 1rem;
  background-color: #ffd57f;
  display: none;
  @media screen and (max-width: 920px) {
    display: inline-block;
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    flex-grox: 0;
    height: 0;
    background-color: #808080;
    border: 0;
    color: white;
    overflow: hidden;
    transition: height 0.25s linear;
    position: absolute;
    right: 0;
    top: 100px;
    ${(props) =>
      !props.active
        ? ``
        : `
      height: 346px;
      border-bottom: 1px solid #ffd57f;
      transition: height 1s linear;
    `}
  }
`;

const Logo = styled.img`
  width: 100px;
  padding: 0 0 0 30px;
  box-sizing: border-box;
  user-select: none;
  @media screen and (max-width: 920px) {
    width: 100px;
    padding-top: 0;
    position: relative;
    left: -30px;
  }
`;

const Bars = styled(FaBars)`
  display: none;
  color: #fff;

  @media screen and (max-width: 920px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
const Times = styled(FaTimes)`
  display: none;
  color: #fff;

  @media screen and (max-width: 920px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 920px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #256ce1;
  padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;
