import React from "react";
import styled from "styled-components";
import DecoHeading from "../components/common/DecoHeading";
import { Text, TextBlock } from "../components/common/Text";

const Pixel = () => {
  return (
    <>
      <MenuPlaceholder></MenuPlaceholder>
      <ContentContainer bgColor="tortfeher">
        <DecoHeading heading="Press" color="lila"></DecoHeading>

        <Text>
          <TextBlock>
            <div>
              <p>Még többet Anton Molnarról az alábbi linkeken találtok:</p>
              <p>
                <a
                  href="http://www.antonmolnar.com/en"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  http://www.antonmolnar.com/en
                </a>
              </p>
              <p>
                <a
                  href="https://startuponline.hu/elinditja-nem-helyettesitheto-tokenjet-a-vilaghiru-magyar-festomuvesz/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://startuponline.hu/elinditja-nem-helyettesitheto-tokenjet-a-vilaghiru-magyar-festomuvesz/
                </a>
              </p>
              <p>
                <a
                  href="https://interpressmagazin.hu/cikkek/anton-molnart-megihlette-a-bitcoin-titokzatos-szuloatyja/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://interpressmagazin.hu/cikkek/anton-molnart-megihlette-a-bitcoin-titokzatos-szuloatyja/
                </a>
              </p>
              <p>
                <a
                  href="https://www.vg.hu/kozelet/2021/11/vilaghiru-magyar-festomuvesz-a-kriptopiacon"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.vg.hu/kozelet/2021/11/vilaghiru-magyar-festomuvesz-a-kriptopiacon
                </a>
              </p>
              <p>
                <a
                  href="https://infostart.hu/kultura/2021/11/19/anton-molnart-megihlette-a-bitcoin-titokzatos-szuloatyja"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://infostart.hu/kultura/2021/11/19/anton-molnart-megihlette-a-bitcoin-titokzatos-szuloatyja
                </a>
              </p>
              <p>
                <a
                  href="https://bbj.hu/business/tech/fintech/anton-molnar%C2%A0launches-his-1st-and-only-nft?fbclid=IwAR2RidDRnQs5mHSDJKsVxG3_U6PYA3t8Ra5MLAvFj5WLHOAn0i-inKogoNE"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://bbj.hu/business/tech/fintech/anton-molnar%C2%A0launches-his-1st-and-only-nft?fbclid=IwAR2RidDRnQs5mHSDJKsVxG3_U6PYA3t8Ra5MLAvFj5WLHOAn0i-inKogoNE
                </a>
              </p>
              <p>
                <a
                  href="https://index.hu/gazdasag/2021/11/19/anton-molnar-nft-satoshi-nakamoto/amp?fbclid=IwAR0tnytr7MOx9h2bXXObKfU6xh52_Ve5Yy2NdIepp98z0guNWQNSwp8zEvY"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://index.hu/gazdasag/2021/11/19/anton-molnar-nft-satoshi-nakamoto/amp?fbclid=IwAR0tnytr7MOx9h2bXXObKfU6xh52_Ve5Yy2NdIepp98z0guNWQNSwp8zEvY
                </a>
              </p>
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
    </>
  );
};

export default Pixel;

const MenuPlaceholder = styled.div`
  height: 80px;
  width: 100%;
  background: url("/hatterek/szurke_minta.jpg");
`;

const ContentContainer = styled.div`
  background-image: url("/hatterek/${(props) => props.bgColor}_hatter_1.jpg");
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center top;
  padding: 5vw 8vw;
  color: ${(props) => (props.bgColor === "szurke" ? "white" : "#808080")};
  & p {
    margin-block-end: 30px;
  }
`;
