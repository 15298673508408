import React from "react";
import styled from "styled-components";
import DecoHeading from "../components/common/DecoHeading";
import { Text, TextBlock } from "../components/common/Text";
import { ContentContainer } from "../components/common/ContentContainer";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
const Pixel = () => {
  return (
    <>
    <ScrollToTopOnMount />
      <MenuPlaceholder></MenuPlaceholder>
      <ContentContainer bgColor="tortfeher">
        <DecoHeading heading="Anton Molnar" color="lila"></DecoHeading>
        <Text>
          <TextBlock>
            <LongText>
              <p>
                {" "}
                "Anton Molnár hedonista művész. Nem úgy, mint a Satyriconból
                ismert Trimalchio, a vagyonában féktelenül tobzódó római
                újgazdag, hanem miként Krúdy hőse, Szindbád, az utazó. Nem duhaj
                habzsoló, hanem szemlélődő ínyenc. Az ízek, és illatok, a női
                szépség feltétlen csodálója, benyomások gyűjtője. Életképek,
                emlékek lenyomata kerül a vászonra. Saját élmények-e vagy inkább
                a nézők vágyai? Nem fontos. Utazunk korokon át, a középkortól a
                monarchia fürdő világáig, a hatvanas évek gyermekjátékaitól a
                mai metropolisok villódzásáig. Utazunk tájról-tájra, a magyar
                pusztától New Yorkon át a Csendes-óceán szigetvilágába. Utazunk
                stílusok között, a reneszánsz és barokk pompájától Monet
                kertjéig, a pop art reklámvilágán keresztül a tasizmus
                fröcskölt, csurgatott technikáján át a külvárosi utcák
                folklórjáig. És végül mindig hazaérünk."
              </p>

              <p>ROCKENBAUER Zoltán, PhD Kurátor, Műcsarnok – Kunsthalle</p>

              <p>
                Anton Molnar (Budapest, 1957–) Párizsban élő magyar képzőművész,
                az egyik legsikeresebb, és legismertebb külföldön élő magyar
                festő. Gyermekkorát Magyarországon és matematika tudós
                édesapjának köszönhetően Afrikában, Bamakóban töltötte. Első
                műve Latabár Kálmánt ábrázolja, amit hároméves korában festett.
              </p>

              <p style={{ fontWeight: 500 }}>Életrajza</p>

              <p>
                A legnagyobb hatás a Képző és Iparművészeti Szakközépiskolában
                érte, ahol festő szakon végzett 1976-ban, majd a Szépművészeti
                Múzeum-ban dolgozott, míg fel nem vették a Képzőművészeti
                Főiskolára, ahol 1983-ban diplomázott festőművészként.
                Lemezborítókat tervezett, plakátokat, grafikákat készített,
                fotózta Tina Turnert és a Dire Straits együttest budapesti
                koncertjükön. Akkoriban teljesen kilátástalannak tűnt, hogy
                itthon festészetével boldoguljon és eltartsa családját
                művészetéből. 1986-ig a főiskolán maradt mesterképzésen. Ebben
                az évben az ENSZ grafikusi munkát ajánlott fel neki Genfben,
                majd két évvel később Párizsban telepedett le családjával, de
                hazáját nem felejtette el. szívében és gondolataiban mindig
                magyar maradt.
              </p>

              <p>
                Franciaországban először kisebb galériákban állította ki képeit,
                később Pierre Cardinnel kezdett el dolgozni, akivel még
                Magyarországon találkozott. A három sikeres kiállítást követően
                jött az Opera Gallery, ahol csaknem két évtizedig volt jelen
                alkotásaival. Egy szerencsés véletlennek köszönhetően
                összeismerkedik Takeshi Sekiguchi sannal Japánban, aki csak
                Picasso és Anton műveket gyűjt mai napig. Képei művészeti
                alapítványok és híres magángyűjteményekben (Cindy Crawford,
                Jacques Chirac) találhatóak, híres világcégek (Louis Vuitton,
                Moët & Henessy) megrendelésére is szokott festeni. A művei
                Picasso, Andy Warhol, Marc Chagall és Jasper Johns alkotásai
                között szerepeltek komoly, tokiói árveréseken.
              </p>

              <p>
                Tanulmányai és a gyerekkori rajzai behelyezik a művészt és
                munkáját abba a korba, ahol él. Életét, munkásságát, szemléletét
                kiválóan jellemzi 2020-ban Magyarországon megrendezett
                kiállítása „Egyszerre minden” címmel. Mindenben meglátja a témát
                és mindenben meglátjuk őt. Műveiben mindig ott van a most,
                személyes alkotó életútja, érdeklődése és saját stílusa.
                Folyamatosan követi korát, látásmódja modern, technikája
                klasszikus.
              </p>

              <p>
                Művészetében mai napig megtalálhatóak a magyar gyökerek. Képein
                keresztül osztja meg véleményét a világról. Utazásait
                festményeivel dokumentálja, melyeken a tartalom sokszor
                áttételesen vagy rejtve keresendő.
              </p>

              <p>
                vente több kiállítása is van világszerte, Párizstól Tokióig,
                Szingapúrtól Los Angelesig rendez kiállításokat, ahova gyűjtői
                is követik. Kiállításai alkalmával szinte mezítelenül odaáll a
                közönség elé és megmutatja a lelkét, megmutatja mire képes.
              </p>

              <p>
                Gyakran látogat haza, és e látogatások alkalmával újabb és újabb
                magyar témájú képek kerülnek fel a vásznára. Számos kiállítása
                volt eddig Magyarországon is. Első budapesti munkáját a Stadion
                szálló igazgatójától kapta, aki felkérte, hogy az olimpiák
                történetét fesse meg. Természetesen a nagy világsztárok, mint
                Mark Spitz, Sonja Henie, Bob Beamon mellett Balczó András, az
                Aranycsapat, Pap Laci és a magyar tőrcsapat is szerepeltek.
              </p>

              <p>
                2004-ben 50 perces portréfilm készült vele A Painter's Travel
                Diary - Portrait of Anton Molnar címmel, melyet a Duna TV is
                bemutatott.
              </p>

              <p>
                2012-ben a 40. Dijon-i "Salon des artistes" díját nyerte el a
                kb. 500 résztevő művészt megelőzve.
              </p>

              <p style={{ fontWeight: 500 }}>Művészete</p>

              <p>
                Klasszikus technikával dolgozik, melyet a flamand és olasz
                reneszánsz mesterek fejlesztettek ki. Festményein sokszor
                szerepel kaligráfia Technikája erősen emlékeztet a 15. és 17.
                századi németalföldi festőkére, de erős hatással van rá az
                1980-as évek amerikai „pop-art”-ja is. Olyan, mintha fára
                festene, ami külön érdekességet ad képeinek.
              </p>

              <p>
                Anton Molnár meglátja az egyszerű tárgyakban a tökéletességet.
                Szinte fotó hűséggel mintázza meg a témát, legyen az saját
                cipője, vagy kalapja. Sajátos szögben fogja meg a tárgyat, hogy
                élesen, és furcsa szögben essen rá a fény. Ez a sajátos nézőpont
                titokzatosságot kölcsönöz a hétköznapi használati eszközöknek,
                és az aprólékos kidolgozás hiperrealista magasságokba emeli
                azokat. Anton Molnár alkotásaiból kirajzolódik valódi
                kozmopolita szemlélete. Egy világutazó, aki a pillanatnyi
                impresszumokat teszi át az alkotásaiba.
              </p>

              <p>
                Csendéletek, portrék és street art alkotások is megtalálhatóak
                munkásságában, melyek betekintést nyújtanak Anton Molnár
                humanista összetett világában. Portréi saját bevallása szerint
                szintén egy utazást jelenítenek meg, mely mögött lélekutak
                állnak, sorsok kultúrák rajzolódnak ki belőle. Sokszínű, nyitott
                érdeklődésű, inspirálódik az előtte lévő nagy eredményekből,
                mesterekből. Szereti a kor emberét és szereti a kor emberének
                életét dokumentálni.
              </p>

              <p>
                Anton Molnár nem csupán festőművész, hanem fotóművész és
                grafikusművész is egyben. A fényképezőgép gyakran visszaköszön
                festményeiben, a festőművész a gépével egy tárgyról tükröződik
                vissza. A grafikusművész is jelen van festményein, amelyeken –
                sajátságos módon – majdnem mindig írott szöveg is van, amely
                tartalmat közvetít az ábrázolt figura vagy tárgy mellett.
              </p>
            </LongText>
          </TextBlock>
        </Text>
      </ContentContainer>
    </>
  );
};

export default Pixel;

const MenuPlaceholder = styled.div`
  height: 80px;
  width: 100%;
  background: url("/hatterek/szurke_minta.jpg");
`;

const LongText = styled.div`
  & p {
    margin-block-end: 25px;
  }
`;
