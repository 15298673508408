import React from "react";
import styled from "styled-components";

const Separator = (props) => {
  return (
    <VideoContainer>
      <VideoPlaceholder src="/hatterek/lila_hatter.jpg" />
      <Video autoPlay muted loop playsInline id="myVideo">
        <source src="/mozgo_hatterek/netu_vid.mp4" type="video/mp4" />
      </Video>
      <SeparatorContent>{props.children}</SeparatorContent>
    </VideoContainer>
  );
};
export default Separator;

const VideoContainer = styled.div`
  width: 100vw;
  min-height: 150px;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Video = styled.video`
  height: 100%;
  width: 393vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 25.4vw; /* 100 * 9 / 16 */
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 0;
  transform: translate(-50%, 0); /* % of current element */
`;
const VideoPlaceholder = styled.img`
  height: 100%;
  width: 393vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 25.4vw; /* 100 * 9 / 16 */
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 0;
  transform: translate(-50%, 0); /* % of current element */
`;

const SeparatorContent = styled.div`
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%); /* % of current element */
  color: white;
`;
