import React from "react";
import styled from "styled-components";

const DecoHeading = (props) => {
  return (
    <HeadingContainer>
      <Deco src={`/tartalom_oldalak/paca_${props.color}.png`}></Deco>
      <Heading>{props.heading}</Heading>
    </HeadingContainer>
  );
};

const HeadingContainer = styled.div`
  position: relative;
  margin-left: 66px;
  margin-bottom: 25px;
`;

const Heading = styled.h1`
  position: relative;
  font-family: "Open sans", serif;
  font-size: 96px;
  font-weight: 700;
  @media screen and (max-width: 920px) {
    font-size: 46px;
    padding-top: 40px;
  }
`;
const Deco = styled.img`
  display: inline-block;
  position: absolute;
  bottom: -30px;
  left: -66px;
`;

export default DecoHeading;
