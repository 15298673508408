import React from "react";
import styled from "styled-components";
import DecoHeading from "../components/common/DecoHeading";
import { Text, TextBlock } from "../components/common/Text";

const Pixel = () => {
  return (
    <>
      <MenuPlaceholder></MenuPlaceholder>
      <ContentContainer bgColor="tortfeher">
        <DecoHeading heading="Szólj bele" color="lila"></DecoHeading>
        <Text>
          <TextBlock>
            <LongText>
              <p>
                A pixel nem más, mint az ILGON blokkláncon létrehozott játék,
                ami lehetőséget ad arra, hogy bárki aki szeretne képes legyen
                beleszólni a fizikai műalkotás életébe.
              </p>

              <p>
                NFT mellett létezik a Netuddki festmény, amin a művész
                lenyűgözően ábrázolja Satoshi Nakamotot. Ezt a festményt senki
                sem birtokolhatja, nincs tulajdonosa, őrzője van, akinek a
                küldetése, hogy megóvja a művet a jövő számára és minél több
                helyen megjelenjen, ezáltal minél többen megismerjék Satoshi
                Nakamoto rejtélyét és ezt az egész ma még titokzatos világot.
              </p>

              <p>
                Szeretnénk nyomot hagyni NFT emlékplakettek formájában, ami az
                eredeti művet szimbolizálja és ünnepli. A kollekció 2021.
                december 15-én jelenik meg az Ilgon blokkláncon és 21000 db
                emlékplakettet tartalmaz, amiből bárkinek lehet egy vagy akár
                több darabja, ezzel megteremtve a lehetőségét egy hosszú
                utazásnak, amit együtt élhetünk át.
              </p>

              <p>
                Az NFT emlékplakett tulajdonában lehetőséged van a mű további
                sorsába beleszólni, hol mennyi ideig és milyen formában jelenjen
                meg a fizikai alkotás. Légy részese ennek a kalandnak.
              </p>

              <p>
                A játék leírása: a játék 2021.december 15-én indul, ha szeretnél
                értesülni a játékról és a további részletekről kérjük add meg az
                email címed, vagy látogass meg minket két hét múlva, mikor is
                jelentkezünk a részletekkel.
              </p>
            </LongText>
          </TextBlock>
        </Text>
      </ContentContainer>
    </>
  );
};

export default Pixel;

const MenuPlaceholder = styled.div`
  height: 80px;
  width: 100%;
  background: url("/hatterek/szurke_minta.jpg");
`;

const ContentContainer = styled.div`
  background-image: url("/hatterek/${(props) => props.bgColor}_hatter_1.jpg");
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center top;
  padding: 5vw 8vw;
  color: ${(props) => (props.bgColor === "szurke" ? "white" : "#808080")};
`;

const LongText = styled.div`
  & p {
    margin-block-end: 25px;
  }
`;
