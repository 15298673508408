import Separator from "../components/common/Separator";
import React from "react";
import styled from "styled-components";
import { InnerHeading } from "../components/common/InnerHeading";
import { InnerLink } from "../components/common/InnerLink";
import { ContentContainer } from "../components/common/ContentContainer";
import { Text, TextBlock } from "../components/common/Text";
import DecoHeading from "../components/common/DecoHeading";
import { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Home = () => {
  const [pic1Front, setPic1Front] = useState(false);
  useEffect(() => {
    document.title = "NeTuddKi - Anton Molnar";
    const interval = setInterval(() => {
      setPic1Front(!pic1Front);
    }, 5000);
    return () => clearInterval(interval);
  }, [pic1Front]);

  return (
    <>
      <VideoContainer>
        <VideoBG>
          <VideoPlaceholder src="/hatterek/nyito_placeholder_allo.png" />
          <Video autoPlay muted loop playsInline id="myVideo">
            <source
              src="/mozgo_hatterek/NeTuddKi_12_sec_medium.mp4"
              type="video/mp4"
            />
          </Video>

          <MainHeading>NE TUDD KI</MainHeading>
        </VideoBG>
      </VideoContainer>
      <ContentContainer bgColor="szurke">
        <DecoHeading heading="Festmény" color="lila"></DecoHeading>
        <Text>
          <TextBlock>
            <LongText>
              <p>
                Anton Molnár festményeinek különlegessége abban rejlik, hogy egy
                klasszikus technikával ábrázolja a modern világot. Szereti a kor
                emberét és szereti a kor emberének ábrázolását ezért is
                született meg a Netuddki festmény, melyből a művész megalkotta a
                Netuddki-t, ami az első, egyetlen és egyben az utolsó NFT
                alkotása.{" "}
              </p>
              <p>
                Ezen művek ábrázolás módja attól különleges, hogy a
                kriptoforradalom kirobbantóját hátulról, arc nélkül, egyszerűen
                csak kapucnis alakként jeleníti meg, teljesen megfosztva
                személyes karakterisztikáitól, így emberi mivoltáról csak az
                alak körvonalai árulkodnak.{" "}
              </p>
              <p>
                Bár Anton Molnár munkája az első világhírű klasszikus festő
                által készített Satoshi ábrázolás, valódi értéke abban rejlik,
                hogy képes egy alkotásban tolmácsolni Satoshi identitásának és
                munkásságának - illetve az általa indított társadalmi és
                technológiai forradalomnak - megannyi üzenetét, míg megőrzi
                annak mélységét és sokrétűségét. Bámulatosan ábrázolja az
                absztrakt háttér előtt álló realista alak, ahogy megérkezünk egy
                ismeretlen új világba.
              </p>
            </LongText>
            <div>
              <img
                src={
                  pic1Front
                    ? "/NeTuddKi_kepek/NeTuddKi_1-2_transparent.png"
                    : "/NeTuddKi_kepek/NeTuddKi_2-1_transparent.png"
                }
                alt="NeTuddKi"
              />
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
      <OpenSeaContainer>
        <br />
        <br />
        <br />
        <nft-card
          contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
          tokenId="98196178464466689993116238850081612969887625248381346180371175427539766083585"
        ></nft-card>
        <br />
        <br />
        <br />
      </OpenSeaContainer>
      <Separator>
        <div>
          A hivatalos AM cím
          <br />
          <br />
        </div>
        <div>0xd9191143581017d3035AD4AA3D5C2452278Ea0a2</div>
      </Separator>

      <ContentContainer bgColor="tortfeher">
        <DecoHeading heading="Anton Molnar" color="lila"></DecoHeading>
        <Text>
          <TextBlock reverse="reverse">
            <LongText>
              <p>
                Anton Molnar 1957-ben született Budapesten, 1988-ban Párizsba
                költözött családjával, ahol azóta is él és alkot. Már a
                kezdetektől olyan híres együttműködői partnerei voltak, mint
                Pierre Cardin és az az Opera Gallery. Képei művészeti
                alapítványok, híres világcégek (Luis Vuitton, Moët & Henessy) és
                hírességek magángyűjteményeiben is megtalálhatóak (Cindy
                Crawford, Jacques Chirac, Takeshi Sekiguchi ).{" "}
              </p>
              <p>
                Festményei Picasso, Andy Warhol, Marc Chagall és Jasper Johns
                alkotásai között szerepeltek komoly, tokiói árveréseken. Életét,
                munkásságát, szemléletét kiválóan jellemzi 2020-ban
                Magyarországon megrendezett kiállítása „Egyszerre minden”
                címmel. Mindenben meglátja a témát és mindenben meglátjuk őt.
                Műveiben mindig ott van a MOST, személyes alkotó életútja,
                érdeklődése és saját stílusa.{" "}
              </p>
              <p>
                Képein keresztül osztja meg véleményét a világról. Festményeiben
                a tartalom sokszor áttételesen vagy rejtve keresendő. Évente
                több kiállítása is van világszerte, Párizstól Tokióig,
                Szingapúrtól Los Angelesig, ahova gyűjtői is követik.Szinte fotó
                hűséggel mintázza meg a témát, az aprólékos kidolgozás
                hiperrealista magasságokba emeli azokat. Anton Molnár
                alkotásaiból sugárzik az élet szeretete és kirajzolódik valódi
                kozmopolita szemlélete. Egy világutazó, aki a pillanatnyi
                impresszumokat teszi át az alkotásaiba.
              </p>
              <p>
                <NavLink to="/hu/anton" style={{ color: "#808080" }}>
                  Olvass tovább &gt;
                </NavLink>
              </p>
            </LongText>
            <div>
              <img src="/tartalom_oldalak/anton_molnar.jpg" alt="Anton" />
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
      <ContentContainer bgColor="szurke">
        <DecoHeading heading="Satoshi Nakamoto" color="lila"></DecoHeading>
        <Text>
          <TextBlock>
            <LongText>
              <p>
                Korunk talán legnagyobb misztériuma Satoshi Nakamoto, aki a
                világ első, működő blokklánc rendszerét alkotta meg. 2008-ban
                publikálta a Bitcoin White Paper-t és 2009. január 3-án
                kibocsátotta a Bitcoint.{" "}
              </p>

              <p>
                Ennek megalkotásához szerte ágazó ismeretekre volt szükség,
                gazdasági, jogi, pénzügyi, kriptográfiai és programozói
                tudásra.Üzenete világos: vége van azoknak az időknek, amikor a
                világot működtető rendszereket központi hatalmak irányítják.{" "}
              </p>

              <p>
                A szabad internet, az információ szabadság és a nyílt
                forráskódon alapuló informatikai rendszerek által korábban nem
                látott módon nyílik lehetősége mindenkinek részt venni a
                következő technológiai áttörésben, ezáltal építve a nem hatalmi
                alapokon nyugvó szebb, közös jövőnket.
              </p>
            </LongText>
            <div>
              <img src="/tartalom_oldalak/Satoshi_kulon.png" alt="Satoshi" />
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
      <OpenSeaContainer>
        <br />
        <br />
        <br />
        <nft-card
          contractAddress="0x495f947276749ce646f68ac8c248420045cb7b5e"
          tokenId="98196178464466689993116238850081612969887625248381346180371175427539766083585"
        ></nft-card>
        <br />
        <br />
        <br />
      </OpenSeaContainer>
      <Separator>
        <InnerHeading>KAPCSOLAT</InnerHeading>
        <InnerLink
          href="mailto:whoever@whoever.wiki"
          rel="noreferrer nooponer"
          targt="_blank"
        >
          whoever@whoever.wiki
        </InnerLink>
      </Separator>
      <ContentContainer bgColor="szurke">
        <DecoHeading heading="Roadmap" color="lila"></DecoHeading>
        <Text>
          <TextBlock>
            <div className="desktop">
              <img src="/roadmap/roadmap_fekvo.png" alt="roadmap" />
            </div>
            <div className="mobile">
              <img src="/roadmap/roadmap_allo.png" alt="roadmap" />
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
    </>
  );
};

export default Home;

const VideoContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const MainHeading = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%); /* % of current element */
  font-size: 10vw;
  font-family: "Cinzel", serif;
  color: white;
  text-shadow: -2px 3px #000000;
  @media screen and (max-width: 520px) {
    font-size: 20vw;
    width: 70vw;
  }
`;

const VideoBG = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`;

const Video = styled.video`
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, calc(-1px - 50%)); /* % of current element */
`;
const VideoPlaceholder = styled.img`
  height: 100%;
  width: 177.77777778vh; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%); /* % of current element */
`;

const OpenSeaContainer = styled.div`
  width: 100%;
  padding: 0 5vw;
  background: #808080;
`;
const LongText = styled.div`
  & p {
    margin-block-end: 25px;
  }
`;
