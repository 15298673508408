import React from "react";
import styled from "styled-components";
import DecoHeading from "../components/common/DecoHeading";
import { Excerpt } from "../components/common/Excerpt";
import { Text, TextBlock } from "../components/common/Text";
import Separator from "../components/common/Separator";
import { InnerHeading } from "../components/common/InnerHeading";
import { InnerLink } from "../components/common/InnerLink";

const Pixel = () => {
  return (
    <>
      <MenuPlaceholder></MenuPlaceholder>
      <ContentContainer bgColor="tortfeher">
        <DecoHeading heading="Festmény" color="lila"></DecoHeading>
        <Excerpt>
          Toror incumor sit amet consectetur adiniscina plit Susnondisco nrotium
          iaculic
        </Excerpt>
        <Text>
          <TextBlock>
            <p>
              sit amet consectetur adiniscina plit Susnondisco nrotium iaculic
              urna id conuallis tollus Dollontosauo nosupre ulla ici at viverrs
              offiritiir arat Etiam cARdINA hendrerit nulla. Integer aliquam
              turnis id sollicitudin compar canvallic rice dianicaim alaifandas
              chan tellus danibus sodales Pollentesa tellus danibus
            </p>
            <p>
              sit amet consectetur adiniscina plit Susnondisco nrotium iaculic
              urna id conuallis tollus Dollontosauo nosupre ulla ici at viverrs
              offiritiir arat Etiam cARdINA hendrerit nulla. Integer aliquam
              turnis id sollicitudin compar canvallic rice dianicaim alaifandas
              chan tellus danibus sodales Pollentesa tellus danibus
            </p>
          </TextBlock>
          <TextBlock>
            <p>
              sit amet consectetur adiniscina plit Susnondisco nrotium iaculic
              urna id conuallis tollus Dollontosauo nosupre ulla ici at viverrs
              offiritiir arat Etiam cARdINA hendrerit nulla. Integer aliquam
              turnis id sollicitudin compar canvallic rice dianicaim alaifandas
              chan tellus danibus sodales Pollentesa tellus danibus
            </p>
            <p>
              sit amet consectetur adiniscina plit Susnondisco nrotium iaculic
              urna id conuallis tollus Dollontosauo nosupre ulla ici at viverrs
              offiritiir arat Etiam cARdINA hendrerit nulla. Integer aliquam
              turnis id sollicitudin compar canvallic rice dianicaim alaifandas
              chan tellus danibus sodales Pollentesa tellus danibus
            </p>
          </TextBlock>
        </Text>
      </ContentContainer>
      <Separator>
        <InnerHeading>NFT Auction</InnerHeading>
        <InnerLink
          href="https://docs.opensea.io/docs/embed"
          rel="noreferrer nooponer"
          targt="_blank"
        >
          https://docs.opensea.io/docs/embed
        </InnerLink>
      </Separator>
      <ContentContainer bgColor="szurke">
        <DecoHeading heading="PIXEL" color="lila"></DecoHeading>
        <Excerpt>
          Toror incumor sit amet consectetur adiniscina plit Susnondisco nrotium
          iaculic
        </Excerpt>
        <Text>
          <TextBlock>
            <p>
              sit amet consectetur adiniscina plit Susnondisco nrotium iaculic
              urna id conuallis tollus Dollontosauo nosupre ulla ici at viverrs
              offiritiir arat Etiam cARdINA hendrerit nulla. Integer aliquam
              turnis id sollicitudin compar canvallic rice dianicaim alaifandas
              chan tellus danibus sodales Pollentesa tellus danibus
            </p>
            <p>
              sit amet consectetur adiniscina plit Susnondisco nrotium iaculic
              urna id conuallis tollus Dollontosauo nosupre ulla ici at viverrs
              offiritiir arat Etiam cARdINA hendrerit nulla. Integer aliquam
              turnis id sollicitudin compar canvallic rice dianicaim alaifandas
              chan tellus danibus sodales Pollentesa tellus danibus
            </p>
          </TextBlock>
          <TextBlock>
            <p>
              sit amet consectetur adiniscina plit Susnondisco nrotium iaculic
              urna id conuallis tollus Dollontosauo nosupre ulla ici at viverrs
              offiritiir arat Etiam cARdINA hendrerit nulla. Integer aliquam
              turnis id sollicitudin compar canvallic rice dianicaim alaifandas
              chan tellus danibus sodales Pollentesa tellus danibus
            </p>
            <p>
              sit amet consectetur adiniscina plit Susnondisco nrotium iaculic
              urna id conuallis tollus Dollontosauo nosupre ulla ici at viverrs
              offiritiir arat Etiam cARdINA hendrerit nulla. Integer aliquam
              turnis id sollicitudin compar canvallic rice dianicaim alaifandas
              chan tellus danibus sodales Pollentesa tellus danibus
            </p>
          </TextBlock>
        </Text>
      </ContentContainer>
    </>
  );
};

export default Pixel;

const MenuPlaceholder = styled.div`
  height: 80px;
  width: 100%;
  background: url("/hatterek/szurke_minta.jpg");
`;

const ContentContainer = styled.div`
  background-image: url("/hatterek/${(props) => props.bgColor}_hatter_1.jpg");
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center top;
  padding: 5vw 8vw;
  color: ${(props) => (props.bgColor === "szurke" ? "white" : "#808080")};
`;
