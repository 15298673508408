import styled from "styled-components";

export const InnerLink = styled.a`
  font-family: "Open sans", serif;
  font-size: 22px;
  font-weight: 300;
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
