import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DecoHeading from "../components/common/DecoHeading";
import { Text, TextBlock } from "../components/common/Text";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../components/wallet/Connectors";
import { ethers } from "ethers";
import Whoever from "../contracts/Whoever.sol/Whoever.json";
import { OnboardingButton } from "../components/common/OnboardingButton";
import MetaMaskOnboarding from "@metamask/onboarding";

const NFT = () => {
  const { active, account, library, connector, activate, deactivate } =
    useWeb3React();

  const onboarding = new MetaMaskOnboarding();

  const isMetamaskInstalled = MetaMaskOnboarding.isMetaMaskInstalled();

  const WhoeverAddress = "0x571a656267711E39AB900eDc187c488782ae3AB7";
  var TransactionResult = "";
  var TransactionReceipt = "";
  var vipNftPrice = "888";

  const [isIlgonChainOk, setIsIlgonChainOk] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(account);
  const [contract, setContract] = useState("");
  const [provider, setProvider] = useState("");
  const [basicNftPrice, setBasicNftPrice] = useState("-");
  const [currentId, setCurrentId] = useState(false);
  const [currentVIPId, setCurrentVIPId] = useState(false);
  console.log("refresh", basicNftPrice);

  async function manageIlgonChain() {
    const ethereum = window.ethereum;
    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x696C6774" }],
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(provider);
      const contract = new ethers.Contract(WhoeverAddress, Whoever, provider);
      setContract(contract);
      fetchPrice(contract).then((price) => {
        setBasicNftPrice(price);
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x696C6774",
                chainName: "ILGON testnet",
                nativeCurrency: {
                  name: "ILGON",
                  symbol: "ILGT",
                  decimals: 18,
                },
                // rpcUrls: ["http://173.249.1.181:48545"],
                rpcUrls: ["https://testnet-rpc.ilgonwallet.com"],
                blockExplorerUrls: ["https://testnet.ilgonexplorer.com/"],
              },
            ],
          });
          setIsIlgonChainOk(true);
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }

  useEffect(() => {
    console.log("manage:", manageIlgonChain());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIlgonChainOk]);

  async function connect() {
    try {
      await activate(injected);
      setIsConnected(true);
    } catch (ex) {
      console.log(ex);
    }
  }

  async function mintNft(account) {
    const options = {
      gasPrice: 100000000,
      gasLimit: 10000000,
      value: ethers.utils.parseEther(basicNftPrice),
    };
    const signer = await provider.getSigner();
    setIsInProgress(true);
    try {
      const WhoeverWithSigner = await contract.connect(signer);
      TransactionResult = await WhoeverWithSigner.mint(
        account,
        `	https://nft.netuddki.com/nfts/NFT_${currentId}.png`,
        options
      );
      console.log("data: ", TransactionResult);
      TransactionReceipt = await TransactionResult.wait();
      console.log("data2: ", TransactionResult);
      setIsInProgress(false);
      fetchPrice(contract).then((price) => {
        setBasicNftPrice(price);
      });
    } catch (error) {
      console.log("baj", error);
      setIsInProgress(false);
    }
  }
  async function mintNftVip(account) {
    const options = {
      gasPrice: 100000000,
      gasLimit: 10000000,
      value: ethers.utils.parseEther(vipNftPrice),
    };
    const signer = await provider.getSigner();
    setIsInProgress(true);
    try {
      const WhoeverWithSigner = await contract.connect(signer);
      TransactionResult = await WhoeverWithSigner.mintVIP(
        account,
        `https://nft.netuddki.com/nfts/vip/NFT_VIP_${currentVIPId}.png`,
        options
      );
      console.log("data: ", TransactionResult);
      TransactionReceipt = await TransactionResult.wait();
      console.log("data2: ", TransactionResult);
      setIsInProgress(false);
      fetchPrice(contract).then((price) => {
        setBasicNftPrice(price);
      });
    } catch (error) {
      console.log("baj", error);
      setIsInProgress(false);
    }
  }

  async function fetchPrice(contract) {
    if (typeof window.ethereum !== "undefined") {
      try {
        const data = await contract.currentPrice();
        const currPrice = ethers.utils.formatEther(data.toString());
        const nextId = await contract.totalSupply();
        const nextVIPId = await contract.VIPtokenCount();
        console.log("nextId", (Number(nextId) + 1).toString().padStart(5, "0"));
        console.log(
          "nextVIPId",
          (Number(nextVIPId) + 1).toString().padStart(5, "0")
        );
        setCurrentId(
          (Number(nextId) + 1).toString().padStart(5, "0").toString()
        );
        setCurrentVIPId(
          (Number(nextVIPId) + 1).toString().padStart(5, "0").toString()
        );
        return currPrice;
      } catch (err) {
        console.log("Error: ", err);
      }
    } else {
      console.log("Error!");
      return "n/a";
    }
  }

  async function disconnect() {
    try {
      deactivate();
    } catch (ex) {
      console.log(ex);
    }
  }

  const printData = () => {
    console.log(TransactionReceipt);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <MenuPlaceholder></MenuPlaceholder>
      <ContentContainer bgColor="tortfeher">
        <DecoHeading heading="Have a say" color="lila"></DecoHeading>
        <Text>
          <TextBlock>
            <LongText>
              <p>
                The Whoever pixel is a game created on the ILGON blockchain,
                giving anyone who wants to have a say in the life of a physical
                work of art a chance to do so.
              </p>

              <p>
                We would like to leave a trace in the form of NFT commemorative
                plaques that symbolize and celebrate the original work. The
                collection will appear on the Ilgon blockchain on December 15,
                2021 and includes 21,000 commemorative plaques, any of which can
                be one or more pieces, creating the opportunity for a long
                journey we can experience together.
              </p>

              <p>
                In the possession of the NFT commemorative plaque, you have the
                opportunity to have a say in the further fate of the work, where
                for how long and in what form the physical work should appear.
                Be part of this adventure.
              </p>
              <p>
                <a
                  href="https://forms.google.com/"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Become a host of Satoshi &gt;&gt;&gt;
                </a>
              </p>
            </LongText>
            <div>
              <img src={"/NFT_site_illu.jpg"} alt="WhoEver" />
            </div>
          </TextBlock>
        </Text>
      </ContentContainer>
      <div style={{ position: "absolute", right: 0, display: "none" }}>
        {active ? (
          <span>
            Connected with <b>{account}</b>
          </span>
        ) : (
          <span>Not connected</span>
        )}
      </div>
      <NftLayout bgColor="tortfeher">
        <NftWrapper>
          <NftTitle>NFT VIP</NftTitle>
          <Nft>
            <hr />
            {currentId ? (
              <NftImage
                src={`https://nft.netuddki.com/nfts/vip/NFT_VIP_${currentVIPId}.png`}
              />
            ) : (
              <NftImage src={`/NFT_placeholder_1.png`} />
            )}
            <hr />
            <NftMeta>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{currentVIPId}</td>
                </tr>
                <tr>
                  <td>PRICE</td>
                  <td>ILG {vipNftPrice}</td>
                </tr>
              </tbody>
            </NftMeta>
            <OnboardingButton />
            {isConnected && active && !isInProgress && (
              <Button onClick={() => mintNftVip(account)}>Claim yours</Button>
            )}
            {isInProgress && (
              <Button onClick={() => void 0}>Please wait</Button>
            )}
            {!isConnected &&
              !active &&
              !isInProgress &&
              isMetamaskInstalled && (
                <Button onClick={connect}>Connect to Metamask</Button>
              )}
          </Nft>
        </NftWrapper>
        <NftWrapper>
          <NftTitle>NFT BASE</NftTitle>
          <Nft>
            <hr />
            {currentId ? (
              <NftImage
                src={`https://nft.netuddki.com/nfts/NFT_${currentId}.png`}
              />
            ) : (
              <NftImage src={`/NFT_placeholder_1.png`} />
            )}
            <hr />
            <NftMeta>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{currentId}</td>
                </tr>
                <tr>
                  <td>PRICE</td>
                  <td>ILG {basicNftPrice.substr(0, 9)}</td>
                </tr>
              </tbody>
            </NftMeta>
            <OnboardingButton />
            {isConnected && active && !isInProgress && (
              <Button onClick={() => mintNft(account)}>Claim yours</Button>
            )}
            {isInProgress && (
              <Button onClick={() => void 0}>Please wait</Button>
            )}
            {!isConnected &&
              !active &&
              !isInProgress &&
              isMetamaskInstalled && (
                <Button onClick={connect}>Connect to Metamask</Button>
              )}
          </Nft>
        </NftWrapper>
      </NftLayout>
    </div>
  );
};

export default NFT;

const MenuPlaceholder = styled.div`
  height: 80px;
  width: 100%;
  background: url("/hatterek/szurke_minta.jpg");
`;

const Button = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #808080;
  margin-top: 15px;
  background-color: white;
  width: 70%;
  &:hover,
  &:active {
    color: white;
    background-color: #cf02d0;
    border-color: #cf02d0;
  }
`;
const NftLayout = styled.div`
  background-image: url("/hatterek/${(props) => props.bgColor}_hatter_1.jpg");
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  padding-top: 40px;
  padding-bottom: 40px;
`;
const NftTitle = styled.h1`
  display: inline-block;
  margin-bottom: 20px;
`;
const NftWrapper = styled.div`
  display: inline-block;
  flex: 0 0 300px;
  text-align: center;
`;
const Nft = styled.div`
  width: 300px;
  min-width: 300px;
  padding: 30px 20px 20px;
  background-color: white;
  border: 1px solid #808080;
  border-radius: 10px;
  text-align: center;
  -webkit-box-shadow: -5px 6px 4px 0px rgba(0, 0, 0, 0.28);
  box-shadow: -6px 6px 4px 0px rgba(0, 0, 0, 0.28);
`;
const NftImage = styled.img`
  width: 100%;
  max-width: 100%;
  margin: 10px 0 20px;
`;
const NftMeta = styled.table`
  width: 100%;
  color: #808080;
  margin: 15px 0 0;
  & tbody tr td:first-child {
    text-align: left;
    font-weight: bold;
  }
  & tbody tr td:last-child {
    text-align: right;
  }
`;
const ContentContainer = styled.div`
  background-image: url("/hatterek/${(props) => props.bgColor}_hatter_1.jpg");
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center top;
  padding: 5vw 8vw;
  color: ${(props) => (props.bgColor === "szurke" ? "white" : "#808080")};
`;
const LongText = styled.div`
  & p {
    margin-block-end: 25px;
  }
`;
